// extracted by mini-css-extract-plugin
export var arH1Mobile = "ArFooter-module--ar-h1-mobile--XQI_A";
export var arH2Mobile = "ArFooter-module--ar-h2-mobile--LAgug";
export var arH3Mobile = "ArFooter-module--ar-h3-mobile--2l6AF";
export var arH4Mobile = "ArFooter-module--ar-h4-mobile--RA9an";
export var arH5Mobile = "ArFooter-module--ar-h5-mobile--31CVo";
export var arH1 = "ArFooter-module--ar-h1--2Rj7J";
export var arH2 = "ArFooter-module--ar-h2--2AZ6O";
export var arH3 = "ArFooter-module--ar-h3--1cFN_";
export var arH4 = "ArFooter-module--ar-h4--3a-Sz";
export var arH5 = "ArFooter-module--ar-h5--1w_Kx";
export var arParagraphXl = "ArFooter-module--ar-paragraph-xl--2GRVZ";
export var arParagraphXlMedium = "ArFooter-module--ar-paragraph-xl-medium--3LG-R";
export var arParagraphBase = "ArFooter-module--ar-paragraph-base--2QYfG";
export var arParagraphBaseMedium = "ArFooter-module--ar-paragraph-base-medium--1hCEx";
export var arParagraphSmall = "ArFooter-module--ar-paragraph-small--1IUM_";
export var arParagraphSmallMedium = "ArFooter-module--ar-paragraph-small-medium--3G9dZ";
export var arParagraphXsmall = "ArFooter-module--ar-paragraph-xsmall--1JHRN";
export var arParagraphXsmallMedium = "ArFooter-module--ar-paragraph-xsmall-medium--14jNP";
export var arLinkMobile = "ArFooter-module--ar-link-mobile--2FBGV";
export var arLinkDesktop = "ArFooter-module--ar-link-desktop--2uePz";
export var arQuote = "ArFooter-module--ar-quote--1tgwt";
export var arQuoteMobile = "ArFooter-module--ar-quote-mobile--3knAd";
export var arGradientLight = "ArFooter-module--ar-gradient-light--1A5Ux";
export var arGradientBlue = "ArFooter-module--ar-gradient-blue--2z0EZ";
export var arTransition = "ArFooter-module--ar-transition--2Bcn-";
export var container = "ArFooter-module--container--2bJUq";
export var contentWrapper = "ArFooter-module--contentWrapper--3vElV";
export var menu = "ArFooter-module--menu--1NHIS";
export var isCopyright = "ArFooter-module--isCopyright--1EPQj";
export var copyrights = "ArFooter-module--copyrights--3w6T7";
export var companyInfo = "ArFooter-module--companyInfo--_DUHm";
export var address = "ArFooter-module--address--T9q0t";