// extracted by mini-css-extract-plugin
export var arH1Mobile = "ArPlatformMobileProductsMenu-module--ar-h1-mobile--1PgQ0";
export var arH2Mobile = "ArPlatformMobileProductsMenu-module--ar-h2-mobile--1ML9h";
export var arH3Mobile = "ArPlatformMobileProductsMenu-module--ar-h3-mobile--1TpvI";
export var arH4Mobile = "ArPlatformMobileProductsMenu-module--ar-h4-mobile--g_tuB";
export var arH5Mobile = "ArPlatformMobileProductsMenu-module--ar-h5-mobile--3S-t3";
export var arH1 = "ArPlatformMobileProductsMenu-module--ar-h1--YrRLN";
export var arH2 = "ArPlatformMobileProductsMenu-module--ar-h2--R8lEo";
export var arH3 = "ArPlatformMobileProductsMenu-module--ar-h3--15bxX";
export var arH4 = "ArPlatformMobileProductsMenu-module--ar-h4--2ogFR";
export var arH5 = "ArPlatformMobileProductsMenu-module--ar-h5--2JQAO";
export var arParagraphXl = "ArPlatformMobileProductsMenu-module--ar-paragraph-xl--1x0Cb";
export var arParagraphXlMedium = "ArPlatformMobileProductsMenu-module--ar-paragraph-xl-medium--2Gkkv";
export var arParagraphBase = "ArPlatformMobileProductsMenu-module--ar-paragraph-base--3Y5lK";
export var arParagraphBaseMedium = "ArPlatformMobileProductsMenu-module--ar-paragraph-base-medium--29fPq";
export var arParagraphSmall = "ArPlatformMobileProductsMenu-module--ar-paragraph-small--Hx1Rn";
export var arParagraphSmallMedium = "ArPlatformMobileProductsMenu-module--ar-paragraph-small-medium--2b3PG";
export var arParagraphXsmall = "ArPlatformMobileProductsMenu-module--ar-paragraph-xsmall--MXMw3";
export var arParagraphXsmallMedium = "ArPlatformMobileProductsMenu-module--ar-paragraph-xsmall-medium--3bICY";
export var arLinkMobile = "ArPlatformMobileProductsMenu-module--ar-link-mobile--15rWE";
export var arLinkDesktop = "ArPlatformMobileProductsMenu-module--ar-link-desktop--MgaeH";
export var arQuote = "ArPlatformMobileProductsMenu-module--ar-quote--18P4z";
export var arQuoteMobile = "ArPlatformMobileProductsMenu-module--ar-quote-mobile--1bmht";
export var arGradientLight = "ArPlatformMobileProductsMenu-module--ar-gradient-light--37CUR";
export var arGradientBlue = "ArPlatformMobileProductsMenu-module--ar-gradient-blue--Q3VyP";
export var arTransition = "ArPlatformMobileProductsMenu-module--ar-transition--1Xc9j";
export var container = "ArPlatformMobileProductsMenu-module--container--1Pof9";
export var show = "ArPlatformMobileProductsMenu-module--show--IrkUc";
export var expanded = "ArPlatformMobileProductsMenu-module--expanded---JzN1";
export var arrowButton = "ArPlatformMobileProductsMenu-module--arrowButton--VrYmy";
export var active = "ArPlatformMobileProductsMenu-module--active--11MHk";