import * as s from './ArFooter.module.scss'

import { LINKEDIN_URL, TWITTER_URL } from 'src/config/constants'

import { ArContainer } from 'src/components/ArContainer'
import { Link } from 'gatsby'
import { ROUTES } from 'src/config/routes'
import React from 'react'
import SvgLinkedin from 'src/assets/svg/linkedin.svg'
import SvgTwitter from 'src/assets/svg/twitter.svg'
import { TArFooterProps } from './ArFooter.types'

const MENU_ITEMS = [
  { title: 'Platform', to: ROUTES.platform() },
  { title: 'Security', to: ROUTES.security() },
  { title: 'About', to: ROUTES.about() },
  { title: 'Privacy', to: ROUTES.privacyPolicy() },
  { title: 'Blog', to: ROUTES.blog() },
  { title: 'Careers', to: ROUTES.careers() },
  { title: `© Artificial Labs ${new Date().getFullYear()}`, isCopyright: true },
  { title: 'Contact', to: ROUTES.contact() },
]

export const ArFooter: React.FC<TArFooterProps> = () => {
  return (
    <ArContainer className={s.container} contentWrapperClassName={s.contentWrapper} full>
      <div className={s.menu}>
        <ul>
          {MENU_ITEMS.map((item, index) => (
            <li key={index} className={item.isCopyright ? s.isCopyright : ''}>
              {item.to ? (
                <Link to={item.to}>{item.title}</Link>
              ) : item.href ? (
                <a href={item.href} target="_blank" rel="noreferrer">
                  {item.title}
                </a>
              ) : (
                item.title
              )}
            </li>
          ))}
        </ul>
        <p className={s.copyrights}>© Artificial Labs {new Date().getFullYear()}</p>{' '}
      </div>
      <div className={s.companyInfo}>
        <div>
          <h6>Artificial</h6>
          <a href="https://goo.gl/maps/1bjaNdY5aLNehXSx9" className={s.address}>
            <span>10 Bow Lane</span>
            <span>London</span>
            <span>EC4M 9AL </span>
            <span>United Kingdom</span>
          </a>
          <a id="footer-contact-link-phone" href="tel:020 3880 8461">
            020 3880 8461
          </a>
          <a id="footer-contact-link-email" href="mailto:hello@artificial.io">
            hello@artificial.io
          </a>
        </div>
        <div>
          <a href={TWITTER_URL} target="_blank" rel="noreferrer">
            <SvgTwitter />
          </a>
          <a href={LINKEDIN_URL} target="_blank" rel="noreferrer">
            <SvgLinkedin />
          </a>
        </div>
      </div>
    </ArContainer>
  )
}
